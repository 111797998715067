import * as React from "react"
import { Box, Paper } from "@mui/material"

export const Root = (props) => (
  <Box
    {...props}
    sx={[
      {
        bgcolor: "background.appBody",
        display: "grid",
        gridTemplateColumns: {
          xs: "1fr",
        },
        gridTemplateRows: "64px 1fr",
        minHeight: "100vh",
      },
      ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
    ]}
  />
)
