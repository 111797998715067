import { Avatar, Box, Button, CardActions, Typography } from "@mui/material"
import * as React from "react"
import Grid from "@mui/material/Unstable_Grid2"
import profiles from "../data/profiles"
import DashboardCard from "../components/DashboardCard"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Divider from "@mui/material/Divider"
import InboxIcon from "@mui/icons-material/Inbox"
import DraftsIcon from "@mui/icons-material/Drafts"

import InfoIcon from "@mui/icons-material/Info"
import SignalWifi1BarIcon from "@mui/icons-material/SignalWifi1Bar"
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount"
import SecurityIcon from "@mui/icons-material/Security"

const SettingsWidget = () => (
  <DashboardCard>
    <List>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="About" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <SignalWifi1BarIcon />
          </ListItemIcon>
          <ListItemText primary="Network" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <SecurityIcon />
          </ListItemIcon>
          <ListItemText primary="Privacy" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <SupervisorAccountIcon />
          </ListItemIcon>
          <ListItemText primary="Admin" />
        </ListItemButton>
      </ListItem>
    </List>
  </DashboardCard>
)

const SettingsPanel = () => {
  return (
    <Grid container spacing={1}>
      <Grid xs={12}>
        <Typography variant="h4" component="h3">
          Settings
        </Typography>
      </Grid>
      <Grid xs={12}>
        <SettingsWidget />
      </Grid>
    </Grid>
  )
}

export default SettingsPanel
