import { Avatar, Button, CardActions, Typography } from "@mui/material"
import * as React from "react"
import Grid from "@mui/material/Unstable_Grid2"
import profiles from "../data/profiles"
import DashboardCard from "../components/DashboardCard"
import MiniChart from "react-mini-chart"
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat"
import WbCloudyIcon from "@mui/icons-material/WbCloudy"
import OpacityIcon from "@mui/icons-material/Opacity"
import CampaignIcon from "@mui/icons-material/Campaign"
import stats_image from "../images/stats2.png"

const stats = [
  {
    stat_label: "Temperature",
    unit: "C",
    value: 21,
    icon: <DeviceThermostatIcon />,
    data: [0, -20, 343, 49.3, -100, 200, 78, 50],
    color_line: "#FF6600",
    color_point: "#FF6600",
  },
  {
    stat_label: "Humidity",
    unit: "",
    value: "Nominal",
    icon: <WbCloudyIcon />,
    data: [0, -20, 343, 49.3, -100, 200, 78, 50],
    color_line: "#5Ec4ff",
    color_point: "#5Ec4ff",
  },
  {
    stat_label: "Air Quality",
    unit: "",
    value: "Nominal",
    icon: <OpacityIcon />,
    data: [1, 2, 1, 2, 2, 3, 2],
    color_line: "#ffe51a",
    color_point: "#ffe51a",
  },
  {
    stat_label: "Noise Level",
    unit: "",
    value: "Nominal",
    icon: <CampaignIcon />,
    data: [1, 2, 1, 2, 2, 3, 2],
    color_line: "#84c94f",
    color_point: "#84c94f",
  },
]

const StatsWidget = ({ stat_label, unit, value, icon, stat, data_set }) => {
  console.log(stat)
  return (
    <>
      <DashboardCard title={stat.stat_label}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            paddingBottom: "1em",
          }}
        >
          {icon && (
            <Avatar
              alt="stat icon"
              sx={{ width: 32, height: 32, color: "#0B739C", bgcolor: "white" }}
            >
              {icon}
            </Avatar>
          )}
          <div>
            <MiniChart
              strokeColor={stat.color_line}
              activePointColor={stat.color_point}
              activePointRadius={8}
              strokeWidth={2}
              labelFontSize={50}
              width={200}
              height={80}
              dataSet={stat.data}
            />
          </div>
          <Typography
            variant="h4"
            sx={{ ml: 1, mr: 2 }}
            style={{ color: "#454545" }}
          >
            {value} {unit && unit}
          </Typography>
        </div>
      </DashboardCard>
    </>
  )
}

const StatsPanel = () => {
  return (
    <Grid container spacing={1}>
      <Grid
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={stats_image} alt="stats" className="statsImage" />
      </Grid>
    </Grid>
  )
}

export default StatsPanel
