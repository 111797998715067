import { Box, Typography } from "@mui/material"
import * as React from "react"

export const SectionTitle = ({ children }) => (
  <Box className="sectionTitle">{children}</Box>
)

export const DashboardHeader = ({ title, children, style = {} }) => (
  <Box className="dashboardHeader">{children}</Box>
)

const DashboardCard = ({ title, children, style = {}, image }) => {
  return (
    <Box className="dashboardCard" style={style}>
      <div className="dashboardHeaderWrapper">
        {title && <DashboardHeader>{title}</DashboardHeader>}
        {image && <img src={image} className="dashboardHeaderImage" />}
      </div>
      {children}
    </Box>
  )
}

export default DashboardCard
