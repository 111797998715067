import { useContext, useState, useEffect } from "react"
import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from "@mui/material"
import { ColorModeContext } from "../contexts"
import weather_new from "../images/weather_new.svg"
import CircleIcon from "@mui/icons-material/Circle"
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone"
import { format } from "date-fns"

const Header = (props) => (
  <Box
    component="header"
    className="Header"
    {...props}
    sx={[
      {
        p: 2,
        gap: 2,
        bgcolor: "transparent",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gridColumn: "1 / -1",
        position: "sticky",
        top: 0,
        zIndex: 1100,
      },
      ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
    ]}
  />
)

export const NavBar = () => {
  const theme = useTheme()
  const colorMode = useContext(ColorModeContext)

  const [currentTime, setTime] = useState(new Date())

  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 10000)
    return () => clearInterval(timer)
  })

  return (
    <Header>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
          padding: 2,
        }}
      >
        <Box
          component="div"
          className="connectedBox"
          sx={{
            ml: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <CircleIcon
            style={{
              verticalAlign: "middle",
              color: "green",
              opacity: 0.5,
              width: 12,
              height: 12,
            }}
          />
          <Typography variant="p" sx={{ p: 0, m: 0 }} className="connectedBox">
            {format(currentTime, "p")}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1.5,
          alignItems: "center",
          fontSize: "2.2rem",
          fontWeight: "bold",
        }}
      >
        Cappsule
      </Box>
      <Box
        className="connectedBox"
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1.5,
          alignItems: "center",
        }}
      >
        <img src={weather_new} className="weather_icon" />
        18.2°C
      </Box>
    </Header>
  )
}
