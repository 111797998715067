import { Avatar, Box, Button, CardActions, Typography } from "@mui/material"
import * as React from "react"
import { format, addDays } from "date-fns"
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"
import Grid from "@mui/material/Unstable_Grid2"
import profiles from "../data/profiles"
import airbnb_icon from "../images/logos/airbnb.png"
import DashboardCard, { SectionTitle } from "../components/DashboardCard"
import NotifictionsPanel from "../components/Notifications"
import ContactHost from "./ContactOwnerDialog"
import travel_date from "../images/travel_date.png"
import guests_image from "../images/guests.png"
import airbnb_image from "../images/airbnb.png"
import host_image from "../images/host.png"

const Host = () => {
  return (
    <>
      <Grid xs={12}>
        <SectionTitle>Host</SectionTitle>
      </Grid>
      <DashboardCard>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            paddingBottom: "1em",
          }}
        >
          <img src={host_image} className="hostImage" />
        </div>
      </DashboardCard>
    </>
  )
}

export const DisplayDate = () => {
  let date_start = new Date()

  return (
    <Box className="dateDisplay">{format(date_start, "EEEE, do LLLL y")} </Box>
  )
}

const HomePanel = () => {
  return (
    <Grid container spacing={1}>
      <Grid xs={12}>
        <DisplayDate />
      </Grid>
      <Grid xs={6}>
        <NotifictionsPanel hasError={true} />
      </Grid>
      <Grid xs={6}>
        <Host />
      </Grid>
    </Grid>
  )
}

export default HomePanel
