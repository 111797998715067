import { useEffect, useState } from "react"
import DashboardCard, { SectionTitle } from "./DashboardCard"
import Grid from "@mui/material/Unstable_Grid2"
import { AlertTitle, Avatar, Typography } from "@mui/material"
import ErrorIcon from "@mui/icons-material/Error"
import { format, parseISO } from "date-fns"
import { Box } from "@mui/system"
import CloseIcon from "@mui/icons-material/Close"
import useSound from "use-sound"
import alertSound from "../sounds/alert.mp3"
import dismissSound from "../sounds/dismiss.mp3"

import ReportProblemIcon from "@mui/icons-material/ReportProblem"
import { API_ENDPOINT } from "../config"

const ErrorAlert = () => <ReportProblemIcon sx={{ color: "red" }} />

const SuccessAlert = () => <ErrorIcon sx={{ color: "green" }} />

const Notification = ({
  level,
  message,
  dissmissable = true,
  onDismissHandler = () => {},
  time_interval = new Date().toISOString(),
}) => {
  let time_string = format(parseISO(time_interval), "p")

  const [activePlayed, setActivePlayed] = useState(false)
  const [playActiveSound, { stop }] = useSound(alertSound, {
    volume: 1.0,
  })
  const [playDismiss] = useSound(dismissSound, { volume: 1.0 })

  useEffect(() => {
    // if (dissmissable === false) return
    playActiveSound()
  }, [undefined])

  const dismissAlertAction = (ev) => {
    ev.preventDefault()
    stop()
    playDismiss()
    onDismissHandler()
  }

  return (
    <Box
      className="notification"
      // onLoad={playActive}
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1.5,
        alignItems: "center",
        mb: "1rem",
        animation: "fadeIn 2s",
      }}
    >
      {<icon />} {level === "alert" ? <ErrorAlert /> : <SuccessAlert />}{" "}
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        <b>{message}</b> at {time_string}
      </div>
      <div
        className="notification__actions"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          marginLeft: "auto",
        }}
      >
        {dissmissable && <CloseIcon onClick={dismissAlertAction} />}
      </div>
    </Box>
  )
}

function getSecondsDiff(startDate, endDate) {
  const msInSecond = 1000

  return Math.round(Math.abs(endDate - startDate) / msInSecond)
}

const NotifictionsPanel = ({ hasError = true }) => {
  const ALERT_URL = `${API_ENDPOINT}/alerts/?active=1`

  const [alerts, setAlerts] = useState([])
  const [trigger, setTrigger] = useState(1)

  const ENABLE_TIMEOUT_ALERTS = process.env.ENABLE_TIMEOUT_ALERTS || false
  const TIMEOUT_ALERTS_TIME = 30 * 5

  const tick = () => {
    setTrigger((prevState) => prevState + 1)
  }

  useEffect(() => {
    const timer = setInterval(() => tick(), 1000)
    return () => clearInterval(timer)
  })

  useEffect(() => {
    const fetchData = async () => {
      await fetch(ALERT_URL)
        .then((res) => res.json())
        .then(
          (res) =>
            res.length &&
            setAlerts((currentAlerts) => [...res, ...currentAlerts]),
        )
        .catch((e) => console.error(e))
    }

    const timeoutAlerts = () =>
      alerts
        .filter((alert) => !alert.user_dismissed)
        .map((alert) => {
          let seconds_since_alert = getSecondsDiff(
            new Date(),
            parseISO(alert.time_interval),
          )

          // time out alerts after TIMEOUT_ALERTS_TIME
          if (seconds_since_alert > TIMEOUT_ALERTS_TIME) {
            alert.user_dismissed = true
            console.log(`faded alert ${alert.id}`)
          }
        })

    ENABLE_TIMEOUT_ALERTS && timeoutAlerts()

    fetchData()
  }, [trigger])

  function dismissAlert(alert_id) {
    return () => {
      let alert = alerts.find((alert) => alert.id === alert_id)
      alert.user_dismissed = true
      setTrigger((prevState) => prevState + 1)
    }
  }

  const hasActiveAlerts = alerts.filter((alert) => !alert.user_dismissed).length

  return (
    <>
      <Grid xs={12}>
        <SectionTitle>Notifications</SectionTitle>
      </Grid>
      <DashboardCard style={{ "padding-top": 0 }}>
        {alerts
          .sort((a, b) =>
            parseISO(a.time_interval) > parseISO(b.time_interval) ? 1 : -1,
          )
          .filter((alert) => !alert.user_dismissed)
          .map((alert, key) => (
            <Notification
              key={key}
              level={alert.alert_level}
              time_interval={alert.time_interval}
              onDismissHandler={dismissAlert(alert.id)}
              message={<>{alert.message}</>}
            />
          ))}

        {!hasActiveAlerts && (
          <Notification
            level="success"
            message={"No issues to report"}
            dissmissable={false}
          >
            <AlertTitle>Ok</AlertTitle>
            No issues to report
          </Notification>
        )}
      </DashboardCard>
    </>
  )
}

export default NotifictionsPanel
