import React from "react"
import Carousel from "react-material-ui-carousel"
import { Button, Box, Typography } from "@mui/material"

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

const CarouselIndicatorSelected = () => (
  <div
    styles={{
      width: "20px",
      height: "9px",
      borderRadius: "2px",
      backgroundColor: "#0C7B93",
    }}
  ></div>
)

const CarouselIndicatorIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* <rect x="5" y="5" width="20" height="9" rx="4" /> */}
  </svg>
)

export function ContentCarousel({ children }) {
  var items = [
    {
      name: "Random Name #1",
      description: "Probably the most random thing you have ever seen!",
    },
    {
      name: "Random Name #2",
      description: "Hello World!",
    },
  ]

  return (
    <Carousel
      //   PrevIcon={<ArrowBackIosIcon />}
      //   NextIcon={<NavigateNextIcon />}
      //   height="100%"

      navButtonsProps={{
        // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
        style: {
          backgroundColor: "#f5fafd",
          color: "#8C8C8C",
          borderRadius: 0,
          width: "18px",
          height: "60px",
          padding: "0px",
          fontSize: "30px",
          margin: "0px",
        },
      }}
      navButtonsWrapperProps={{
        // Move the buttons to the bottom. Unsetting top here to override default style.
        style: {
          bottom: "0",
          top: "unset",
          width: "30px",
          padding: "2px",
        },
      }}
      NextIcon={
        <ChevronRightIcon style={{ height: "60px", fontSize: "60px" }} />
      } // Change the "inside" of the next button to "next"
      PrevIcon={
        <ChevronLeftIcon style={{ height: "60px", fontSize: "60px" }} />
      }
      IndicatorIcon={<CarouselIndicatorIcon />}
      indicatorIconButtonProps={{
        style: {
          // padding: "0", // 1
          borderRadius: "7px",
          width: "22px",
          height: "10px",
          backgroundColor: "#89CCDB",
          // color: "blue", // 3
          marginRight: "5px",
        },
      }}
      activeIndicatorIconButtonProps={{
        style: {
          backgroundColor: "#0C7B93", // 2
        },
      }}
      autoPlay={false}
      styles={{ minHeight: "400px" }}
      navButtonsAlwaysVisible={true}
      style={{ flexdirection: "row", flexgrow: 1 }}
      className="carousel"
      swipe={true}
      indicatorContainerProps={{
        style: {
          bottom: 10,
          position: "fixed",
        },
      }}
    >
      {children.map((child, i) => (
        <CarouselWrapper styles={{ minHeight: "400px" }} key={i}>
          {child}
        </CarouselWrapper>
      ))}
    </Carousel>
  )
}

function CarouselWrapper({ children }) {
  return (
    <Box
      style={{
        height: "100%",
        minheight: "100%",
        margin: "1em 5em 1em 5em",
        flexgrow: 1,
      }}
      className="carousel-wrapper"
    >
      {children}
    </Box>
  )
}
