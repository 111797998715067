import { Avatar, Box, Button, CardActions, Typography } from "@mui/material"
import * as React from "react"
import { format, addDays } from "date-fns"
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"
import Grid from "@mui/material/Unstable_Grid2"
import profiles from "../data/profiles"
import airbnb_icon from "../images/logos/airbnb.png"
import DashboardCard, { SectionTitle } from "../components/DashboardCard"
import NotifictionsPanel from "../components/Notifications"
import ContactHost from "./ContactOwnerDialog"
import travel_date from "../images/travel_date.png"
import guests_image from "../images/guests.png"
import airbnb_image from "../images/airbnb.png"
import host_image from "../images/host.png"
import { DisplayDate } from "./Home"

const TravelDates = () => {
  let date_start = new Date()
  const numberOfDaysToAdd = 6
  let date_end = addDays(date_start, numberOfDaysToAdd)

  const travel_format = "EEE do MMM"
  //   const checkout_format = "EEEE"

  return (
    <DashboardCard title="Travel Date" style={{ minHeight: "136px" }}>
      <img src={travel_date} className="travelDatesImage" />
    </DashboardCard>
  )
}

const Guests = () => {
  return (
    <DashboardCard title="Guests" image={airbnb_image}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          paddingBottom: "1em",
        }}
      >
        <img src={guests_image} className="guestsImage" />
      </div>
    </DashboardCard>
  )
}

const StayPanel = () => {
  return (
    <Grid container spacing={1}>
      <Grid xs={12}>
        <DisplayDate />
      </Grid>
      <Grid xs={6}>
        <TravelDates />
      </Grid>
      <Grid xs={6}>
        <Guests />
      </Grid>
    </Grid>
  )
}

export default StayPanel
