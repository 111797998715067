import { Box } from "@mui/material"
import * as React from "react"

export const Main = (props) => (
  <Box
    component="main"
    className="Main"
    {...props}
    sx={[{ p: 0 }, ...(Array.isArray(props.sx) ? props.sx : [props.sx])]}
  />
)
