import { Avatar, Box, Button, CardActions, Typography } from "@mui/material"
import * as React from "react"
import Grid from "@mui/material/Unstable_Grid2"

import cappsule_go from "../images/cappsule_go_page.png"

const TagPanel = () => {
  return (
    <Grid container spacing={1}>
      <Grid xs={12}>
        <img src={cappsule_go} alt="cappsule_go" style={{ width: "90%" }} />
      </Grid>
    </Grid>
  )
}

export default TagPanel
