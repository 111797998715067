import * as React from "react"
import Typography from "@mui/material/Typography"
import { Root } from "./layouts/Root"
import { NavBar } from "./layouts/NavBar"
import { Main } from "./layouts/Dashboard"
import { ContentCarousel } from "./layouts/Carousel"
import { ColorModeContainer } from "./components/ColorToggle"
import { ThemeProvider } from "@emotion/react"
import theme from "./theme"
import HomePanel from "./layouts/Home"
import StatsPanel from "./layouts/Stats"
import SettingsPanel from "./layouts/Settings"
import LocalPanel from "./layouts/Weather"
import StayPanel from "./layouts/Stay"
import TagPanel from "./layouts/Tags"

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Root>
        <NavBar />
        <Main>
          <ContentCarousel>
            <HomePanel />
            <StayPanel />
            <StatsPanel />
            <LocalPanel />
            <TagPanel />
            <SettingsPanel />
          </ContentCarousel>
        </Main>
      </Root>
    </ThemeProvider>
  )
}

export default App
