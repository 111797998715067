import { Avatar, Box, Button, CardActions, Typography } from "@mui/material"
import * as React from "react"
import Grid from "@mui/material/Unstable_Grid2"
import profiles from "../data/profiles"
import DashboardCard from "../components/DashboardCard"

import map_image from "../images/map.png"
import weather_image from "../images/weather@0.25x.png"
import review_image from "../images/reviews.png"

const MapWidget = () => (
  <Box>
    <img src={map_image} alt="map" style={{ width: "90%" }} />
  </Box>
)

const WeatherWidget = () => (
  <Box>
    {weather_image && (
      <img src={weather_image} alt="weather" style={{ width: "60%" }} />
    )}
  </Box>
)

const ReviewWidget = () => (
  <Box>
    {review_image && (
      <img
        src={review_image}
        alt="reviews"
        style={{ width: "55%", marginLeft: "10%" }}
      />
    )}
  </Box>
)

const LocalPanel = () => {
  return (
    <Grid container spacing={1}>
      <Grid xs={12}>
        <Box className="dateDisplay">
          Local Information for Brisbane, Queensland
        </Box>
      </Grid>
      <Grid xs={6}>
        <WeatherWidget />
        <ReviewWidget />
      </Grid>
      <Grid xs={6}>
        <MapWidget />
      </Grid>
    </Grid>
  )
}

export default LocalPanel
